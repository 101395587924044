import React from 'react'
import './Header.css';
import logoTVA from '../asset/RC_Logo_Julia_pos.svg';
import refresh from '../asset/newRefresh.png';
import {Button, Col, Container, Row} from "react-bootstrap";

const Header = ({onReset}) => (

    <Container fluid>
        <style type="text/css">
            {`
                    .btn-restart {
                        color: #FFFFFF;
                       
                    }
                `}
        </style>
        <Row className="header-web-chat">
            <Col xs={4} sm={4} md={2} lg={2}>
                <img className="header-logo" alt='logo' src={logoTVA}/>

            </Col>
            <Col xs={5} sm={5} md={8} lg={8}>
            <div className="header-webchat content">
                <div className="header-content">ROME VIRTUAL ASSISTANT</div>

            </div>
            </Col>
            <Col  className="d-flex justify-content-end">
                <Button className="restart" variant="restart" size="sm" onClick={e => onReset(e)}>
                    <img className="icon"
                         src={refresh}
                         alt="Riavvia">
                    </img>
                </Button>
            </Col>
        </Row>
    </Container>
);

export default Header
