import {useLocation} from 'react-router-dom';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import ReactWebChat, {createDirectLine, createStore} from 'botframework-webchat';
import styleOptions from '../asset/js/styleSet.js';
import Header from "./Header";
import './WebChat.css';
import {Col, Container, Row} from "react-bootstrap";
import utils from '../helpers/Utils'
import sendButton from '../asset/newSend.png';
import {v4 as uuidv4} from 'uuid';
import markdownit from 'markdown-it'


const WebChat = (factory, deps) => {


    const {search} = useLocation();

    //initialize directLine state

    const [styleSet, setStyleOptions] = useState();
    const [session, setSession] = useState();

    useMemo(() => setStyleOptions(styleOptions()), []);

    const initConversation = useCallback(() => {

        let myuuid = uuidv4();
        if (getCookie("userId") == null) document.cookie = "userId=" + myuuid + "; SameSite=None; Secure"


        // var timer = setTimeout(() => {
        //     clearTimeout(timer);
        //     onReset();
        // }, 3600000);


        setSession(false);

        (async function () {
            const resp = await fetchToken();
            const token = resp.token;
            const respSpeech = await fetchTokenSpeech();
            const tokenSpeech = respSpeech.token
            const region = respSpeech.region
            const key = Date.now();

            async function createHybridPonyfillFactory() {

                // Create the ponyfill factory function, which can be called to create a concrete implementation of the ponyfill.
                const speechServicesPonyfillFactory = await window.WebChat.createCognitiveServicesSpeechServicesPonyfillFactory(
                    {
                        // We are passing the Promise function to the "credentials" field.
                        // This function will be called every time the token is used.
                        credentials: {
                            region: region,
                            authorizationToken: tokenSpeech,
                        }
                    }
                );
                const webSpeechPonyfillFactory = await window.WebChat.createBrowserWebSpeechPonyfillFactory();

                return options => {
                    // We are using two ponyfill factories to create two concrete ponyfills.
                    const speechServicesPonyfill = speechServicesPonyfillFactory(options);
                    const webSpeechPonyfill = webSpeechPonyfillFactory(options);
                    // And we are merging the result from two different ponyfills.
                    // We use browser-supported speech for speech-to-text, and Speech Services for text-to-speech.
                    return {
                        SpeechGrammarList: webSpeechPonyfill.SpeechGrammarList,
                        SpeechRecognition: webSpeechPonyfill.SpeechRecognition,
                        // Turn text-to-speech on/off. Return null for off.
                        // speechSynthesis: speechServicesPonyfill.speechSynthesis,
                        // SpeechSynthesisUtterance: speechServicesPonyfill.SpeechSynthesisUtterance
                        speechSynthesis: null,
                        SpeechSynthesisUtterance: null
                    }
                };
            }
            
            setSession({
                directLine: createDirectLine({
                    token: token,
                    //secret:"",
                    conversationId: getCookie("conversationId"),
                    webSocket: true,
                    watermark: "0",
                }),
                selectVoice: (voices, activity) => {
                    return activity.locale === 'it-IT'
                        ? voices.find(({name}) => /IsabellaNeural/iu.test(name))
                        : voices.find(({name}) => /IsabellaNeural/iu.test(name)) ||
                        voices.find(({name}) => /IsabellaNeural/iu.test(name))
                },
                key,
                webSpeechPonyfillFactory: await createHybridPonyfillFactory(),
                store: createStore({}, ({dispatch}) => next => action => {
                    const userLocale = navigator.language || navigator.userLanguage;
                    if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED' && getCookie("conversationId") === null) {
                        dispatch({
                            type: 'WEB_CHAT/SEND_EVENT',
                            payload: {
                                name: 'webchat/join',
                                value: {locale: userLocale},
                                
                            }
                        });
                    } else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
                        let activity = action.payload.activity
                        let conversationId = activity.conversation.id
                        document.cookie = "conversationId=" + conversationId + "; Max-Age=3600; SameSite=None; Secure"
                        var cards = document.getElementsByClassName("ac-adaptiveCard");
                        for (let i = 0; i < cards.length; i++) {
                            //cards[i].onclick = null
                            cards[i].style.pointerEvents = "none";
                        }
                        var buttons = document.getElementsByClassName("ac-pushButton");
                        for (let i = 0; i < buttons.length; i++) {
                            buttons[i].onclick = null
                            buttons[i].style.pointerEvents = "none";
                        }
                        if (action.payload.activity.type === "typing" || action.payload.activity.from.role === 'user') {
                            // clearTimeout(timer);
                            // timer = setTimeout(() => {
                            //     clearTimeout(timer);
                            //     onReset();
                            // }, 3600000);

                        }
                        if (action.payload.activity.type === "event") {

                            streaming(activity)
                        }
                        if (action.payload.activity.from.id.startsWith("dl_")) {
                            action.payload.activity.from.role = "user"

                        }

                        if (action.payload.activity.from.role === 'user') {
                            action.payload.activity.channelData.state = "sent"
                            if (action.payload.activity.channelData.state === "sent" && action.payload.activity.type !== "typing") {
                                disableKeyboard()
                                disableMic()
                            }
                        }
                        if (action.payload.activity.from.role === 'bot') {
                            if (activity.attachments === undefined && activity.value === undefined) {
                                enableSendBoxMain()
                                enableInput()
                                enableKeyboard()
                                enableMic()
                            } else if (activity.attachments !== undefined && activity.attachments.length > 0 && activity.value !== undefined && activity.value !== null && activity.value.enableInput === true) {
                                enableSendBoxMain()
                                enableInput()
                                enableKeyboard()
                            } else if (activity.attachments !== undefined && activity.attachments.length > 0) {
                                disableInput()
                                disableSendBoxMain()
                                disableKeyboard()
                            }
                            return next(action)
                        }

                    }
                    return next(action);
                })
            });
        })();
    }, [setSession]);
    useEffect(initConversation, [initConversation]);

    async function fetchToken() {
        let url = "/api/directline/token"
        if (!utils.getEnv("REACT_APP_TOKEN_BASE_URL") === undefined || utils.getEnv("REACT_APP_TOKEN_BASE_URL"))
            url = utils.getEnv("REACT_APP_TOKEN_BASE_URL") + url
        const res = await fetch(url, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({conversationId: getCookie("conversationId")})
        });
        return await res.json();
    }

    async function fetchTokenSpeech() {
        let url = "/api/speech/token"
        if (!utils.getEnv("REACT_APP_TOKEN_BASE_URL") === undefined || utils.getEnv("REACT_APP_TOKEN_BASE_URL"))
            url = utils.getEnv("REACT_APP_TOKEN_BASE_URL") + url
        const res = await fetch(url, {
            method: 'GET',
        });
        return await res.json();
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    function disableSendBoxMain() {
        let input = document.getElementsByClassName("webchat__send-box__main");
        if (input[0] !== null && input[0] !== undefined)
            input[0].style.display = "none"
        if (input[1] !== null && input[1] !== undefined)
            input[1].style.display = "none"
    }

    function disableInput() {
        let input = document.getElementsByClassName("webchat__send-box");
        if (input[0] !== null && input[0] !== undefined)
            input[0].style.display = "none"
    }

    function enableInput() {
        let input = document.getElementsByClassName("webchat__send-box");
        // let ls = document.getElementsByClassName("webchat__send-box__main");
        // const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        // const img = document.createElementNS('http://www.w3.org/2000/svg', 'image');
        // //const child = ls[0].children[1].getElementsByTagName('svg');
        // const form = ls[0].children[0]
        // const children = ls[0].children[1].cloneNode(true).children[0]
        // const child = children.getElementsByTagName('svg');
        // img.setAttributeNS('http://www.w3.org/1999/xlink', 'href', sendButton);
        // img.setAttribute('height', '28');
        // img.setAttribute('width', '28');
        // // img.src= sendButton;
        // svg.setAttribute('height', '28');
        // svg.setAttribute('width', '28');
        // svg.appendChild(img);
        // //children[0].replaceWith(svg)
        // child[0].replaceWith(svg)
        // children.className = ""
        // children.classList.add(
        //     "webchat__icon-button",
        //     "webchat__icon-button--stretch",
        //     "webchat__send-button",
        //     "webchat__send-box__button",
        //     "webchat__send-box__button--align-stretch"
        // );
        // children.title = "Invia";
        // children.addEventListener('click', submit);
        // ls[0].appendChild(children)
        input[0].style.display = "block"
    }


    function enableSendBoxMain() {
        let input = document.getElementsByClassName("webchat__send-box__main");
        input[0].style.display = "flex"
    }

    function disableKeyboard() {
        let input = document.getElementsByClassName("webchat__send-box-text-box__input");
        let button = document.getElementsByClassName("webchat__send-button");
        if (input[0])
            input[0].disabled = true
        if (button[0])
            button[0].disabled = true
    }

    function enableKeyboard() {
        let input = document.getElementsByClassName("webchat__send-box-text-box__input");
        let button = document.getElementsByClassName("webchat__send-button");
        if (input[0])
            input[0].disabled = false
        if (button[0])
            button[0].disabled = false
    }
    function disableMic() {
        let input = document.getElementsByClassName("webchat__send-box-text-box__input");
        let mic = document.getElementsByClassName("webchat__microphone-button__button");
        if (input[0])
            input[0].disabled = true
        if (mic[0]) {
            mic[0].disabled = true
        }
    }

    function enableMic() {
        let input = document.getElementsByClassName("webchat__send-box-text-box__input");
        let mic = document.getElementsByClassName("webchat__microphone-button__button");
        if (input[0])
            input[0].disabled = false
        if (mic[0])
            mic[0].disabled = false
    }

    function onReset(e) {
        document.cookie = "conversationId=; Max-Age=-99999999; SameSite=None; Secure";
        // var maxId = setTimeout(function(){}, 0);
        // for(var i=0; i < maxId; i+=1) {
        //     clearTimeout(i);
        // }
        initConversation()
    }

    function streaming(activity) {
        disableKeyboard()
        disableMic()
        const md = markdownit()
        let botMessage = document.getElementsByClassName("webchat__stacked-layout__message-row");
        if (botMessage && botMessage[botMessage.length - 1]) {
            let lastMessage = botMessage[botMessage.length - 1].children[1]
            if (lastMessage) {
                let child = lastMessage.children[1]; // Assuming the content element is the second child
                // Check if child exists before accessing its class
                if (child && child.classList.contains("webchat__bubble__content")) {
                    let content = child; // Assign the element to the content variable
                    if (activity.value.text !== "") {
                        
                        content.querySelector('div').innerHTML = md.render(activity.value.text)
                        content.querySelector('div').innerHTML=content.querySelector('div').innerHTML.replace(/<a\s+/g, '<a target="_blank" ');

                    }
                } else {
                    // Handle the case where the second child doesn't have the desired class
                    console.warn("Element with class 'webchat__bubble__content' not found in the second child.");
                }
            } else {
                // Handle the case where no message elements are found
                console.warn("No elements with class 'webchat__stacked-layout__message-row' found.");
            }
        }
        if (activity.value.end){

            enableKeyboard()
            enableMic()
        }
       
    }

// const middleware = () => next => action => {
//         switch (action.activity.type) {
//             case 'event':
//                 useActivities
//                 return next(action)
//
//             default:
//                 return next(action);
//         }
//     };
    return (
        <>
            {
                (session)
                    ?
                    <Container fluid="true">
                        <Row className="m-0 p-0">
                            <Col className=" m-0 p-0">
                                <Header onReset={e => onReset(e)}>
                                </Header>
                                <ReactWebChat className="webchat"
                                              userID={getCookie("userId")}
                                              directLine={session.directLine}
                                              key={session.key}
                                              store={session.store}
                                              styleOptions={styleSet}
                                    //activityMiddleware={middleware}
                                              sendTypingIndicator={true}
                                              webSpeechPonyfillFactory={session.webSpeechPonyfillFactory}
                                              selectVoice={session.selectVoice}
                                />
                            </Col>

                        </Row>
                    </Container>
                    : <div>
                        Caricamento...
                    </div>
            }
        </>
    )
};

export default WebChat;
